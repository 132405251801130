<template>
  <div class="app-container device-list-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createDevice">添加协议</el-button>
      </span>
    </div>
    <div class="table-container">
      <finalTable
          ref="finalTableRef"
          :datas="dataset"
          @tableEventHandler="tableEventHandler"
      />
    </div>

    <!-- 添加协议对话框 -->
    <el-dialog
        :visible.sync="device.addDialogVisible"
        :before-close="cancelDeviceSave"
        width="728px"
        :close-on-click-modal="false"
    >
      <template slot="title">
        {{ device.title }}
      </template>
      <el-form
          label-position="top"
          :model="device.form"
          ref="deviceForm"
          :rules="device.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
                :label="$t('vlink.device.protocolName')"
                prop="protocolName"
            >
              <el-input
                  v-model="device.form.protocolName"
                  maxlength="32"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.device.protocolType')" prop="protocolType">
              <el-select
                  :style="{ width: '100%' }"
                  filterable
                  clearable
                  v-model="device.form.protocolType"
              >
                <el-option
                    v-for="item in options"
                    :key="item.typeValue"
                    :label="item.label"
                    :value="item.typeValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
                :label="$t('vlink.tsl.description')"
                prop="description"
            >
              <el-input
                  v-model="device.form.description"
                  maxlength="32"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                :label="$t('vlink.tsl.extendConfig')"
                prop="extendConfig"
            >
              <el-input
                  v-model="device.form.extendConfig"
                  maxlength="32"
                  :show-word-limit="true"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
            $t("commons.cancel")
          }}</el-button>
        <el-button type="primary" @click="handleDeviceSave">{{
            $t("commons.save")
          }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  updateProtocol,
  createProtocol,
  deleteProtocolById,
  updateEnableStatus,
  exportDevice,
  findProtocolPage,
} from "@/api/ruge/vlink/device/device";
export default {
  components: {
    finalTable,
  },
  data() {
    return {
      successObj: {
        successDialogShow: false,
        deviceName: "",
        productKey: "",
      },
      loadingFlag: false,
      productList: [],
      options: [{
        typeValue: 'private',
        label: '私有协议'
      }, {
        typeValue: 'authority',
        label: '官方协议'
      }],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
          prop: "protocolId",
          width: "18",
        },
        header: [
          {
            prop: "protocolName",
            label: this.$t("vlink.device.protocol"),
            width: "",
          },
          {
            prop: "protocolType",
            label: this.$t("vlink.device.protocolType"),
            width: "",
          },

          {
            prop: "extendConfig",
            label: this.$t("vlink.tsl.extendConfig"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        searchLineConfig: {
          protocolName: {
            type: "input",
            label: "协议名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入协议名称",
            prefixIcon: "el-icon-search",
          },
          protocolType: {
            type: "selector",
            label: "节点类型",
            value: "",
            placeholder: "请选择节点类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "私有协议",
                value: "private",
              },
              {
                label: "官方协议",
                value: "authority",
              },
            ],
          },

        },
        // 表格内容配置
        detailConfig: {
          protocolType: {
            type: "tags",
            private : {
              type: "private",
              label: "私有协议",
            },
            authority: {
              type: "authority",
              label: "官方协议",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          deviceName: null,
          deviceStatus: null,
          nodeType: null,
          productKey: null,
          registerStartTime: null,
          registerEndTime: null,
          activeStartTime: null,
          activeEndTime: null,
          lastStartTime: null,
          lastEndTime: null,
        },
        multipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        form: {
          protocolId:null,
          protocolName: null,
          protocolType: null,
          description: null,
          extendConfig: null,
        },
        formRules: {
          protocolName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          protocolType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getDevicePage();
  },
  mounted() {
    // 从总览跳转过来
    const { type } = this.$route.query;
    if (type === "device" || type === "gateway") {
      this.device.title = this.$t("vlink.device.add");
      this.device.addDialogVisible = true;
      if (type === "gateway") {
        this.$nextTick(() => {
          this.device.form.productId = "GraZGrYZZW8";
        });
      }
    }
  },
  methods: {
    jumpHanlder(type) {
      switch (type) {
        case "deviceDetail":
          this.$router.push({
            path: "/iot/device/detail",
            query: {
              productKey: this.successObj.productKey,
              deviceName: this.successObj.deviceName,
            },
          });
          break;
        case "deviceNew":
          this.successObj.successDialogShow = false;
          this.createDevice();
          break;
      }
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findDevicePage();
    },
    exportHandler() {
      delete this.device.listQuery.activationTime;
      delete this.device.listQuery.creationDate;
      delete this.device.listQuery.lastOnlineTime;
      exportDevice(this.device.listQuery).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "设备列表",
          taskStatus: 0,
          rootPath: "thingsPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    // 取消保存
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
    },
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.device.form.protocolId) {
            updateProtocol(this.device.form)
                .then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.cancelDeviceSave();
                  this.findDevicePage();
                })
                .catch((error) => {
                  console.log(`保存失败，原因 => ${error}`);
                });
          } else {
            createProtocol(this.device.form)
                .then((res) => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.cancelDeviceSave();
                  this.findDevicePage();
                  this.showSuccessDialog(res);
                })
                .catch((error) => {
                  console.log(`保存失败，原因 => ${error}`);
                });
          }
        }
      });
    },
    showSuccessDialog({ deviceName, productKey }) {
      this.successObj.successDialogShow = true;
      this.successObj.deviceName = deviceName;
      this.successObj.productKey = productKey;
    },
    closeDeviceConfig(v) {
        this.updateProtocol();
    },
    updateProtocol() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.device.form.deviceId) {
            updateProtocol(this.device.form)
                .then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.cancelDeviceSave();
                  this.findDevicePage();
                })
                .catch((error) => {
                  console.log(`保存失败，原因 => ${error}`);
                });
          } else {
            if (this.device.form.deviceCode==null){
              this.device.form.deviceCode = this.device.form.deviceName
            }
            createDevice(this.device.form)
                .then(() => {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.cancelDeviceSave();
                  this.findDevicePage();
                })
                .catch((error) => {
                  console.log(`保存失败，原因 => ${error}`);
                });
          }
        }
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // 处理时间参数
        if (datas.params.tagList && datas.params.tagList.length > 0)
          datas.params.tagList = datas.params.tagList.join(",");
        this.dealTime(datas);
        this.device.listQuery = { ...this.device.listQuery, ...datas.params };
        this.findDevicePage();
      } else if (datas.type === "paginationChange") {
        this.device.listQuery.current = datas.params.current.page;
        this.device.listQuery.rowCount = datas.params.current.limit;
        this.getDevicePage();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editDevice(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.protocolId);
            break;
        }
      }
    },
    dealTime(datas) {
      // 注册时间
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.device.listQuery.registerStartTime = datas.params.creationDate[0]
            ? new Date(datas.params.creationDate[0]).getTime()
            : null;
        this.device.listQuery.registerEndTime = datas.params.creationDate[1]
            ? new Date(datas.params.creationDate[1]).getTime()
            : null;
        delete datas.params.creationDate;
      } else {
        if (
            datas.params.creationDate &&
            datas.params.creationDate.length === 0
        ) {
          this.device.listQuery.registerStartTime = "";
          this.device.listQuery.registerEndTime = "";
        }
      }
      // 激活时间
      if (
          datas.params.activationTime &&
          datas.params.activationTime.length > 0
      ) {
        this.device.listQuery.activeStartTime = datas.params.activationTime[0]
            ? new Date(datas.params.activationTime[0]).getTime()
            : null;
        this.device.listQuery.activeEndTime = datas.params.activationTime[1]
            ? new Date(datas.params.activationTime[1]).getTime()
            : null;
        delete datas.params.activationTime;
      } else {
        if (
            datas.params.activationTime &&
            datas.params.activationTime.length === 0
        ) {
          this.device.listQuery.activeStartTime = "";
          this.device.listQuery.activeEndTime = "";
        }
      }
      // 最后上线时间
      if (
          datas.params.lastOnlineTime &&
          datas.params.lastOnlineTime.length > 0
      ) {
        this.device.listQuery.lastStartTime = datas.params.lastOnlineTime[0]
            ? new Date(datas.params.lastOnlineTime[0]).getTime()
            : null;
        this.device.listQuery.lastEndTime = datas.params.lastOnlineTime[1]
            ? new Date(datas.params.lastOnlineTime[1]).getTime()
            : null;
        delete datas.params.lastOnlineTime;
      } else {
        if (
            datas.params.lastOnlineTime &&
            datas.params.lastOnlineTime.length === 0
        ) {
          this.device.listQuery.lastStartTime = "";
          this.device.listQuery.lastEndTime = "";
        }
      }
      return datas;
    },
    createDevice() {
      this.device.title = this.$t("vlink.device.protocolAdd");
      this.device.form = {
        protocolId:null,
        protocolName: null,
        protocolType: null,
        description: null,
        extendConfig: null,
      };
      this.device.addDialogVisible = true;
    },
    updateEnableStatus(row) {
      updateEnableStatus(row)
          .then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.saveSuccess"),
              type: "success",
            });
            this.findDevicePage();
          })
          .catch((error) => {
            this.findDevicePage();
            console.log(`保存失败，原因 => ${error}`);
          });
    },
    deleteDevice(protocolId) {
      this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
      )
          .then(() => {
            deleteProtocolById({ protocolId }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.findDevicePage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
    },
    // 查看详情
    viewDeviceDetail(row) {
      this.$router.push({
        path: "/iot/device/detail",
        query: {
          productKey: row.productKey,
          deviceName: row.deviceName,
        },
      });
    },
    findDevicePage() {
      this.device.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getDevicePage();
    },
    editDevice(row) {
      this.currentState = "edit";
      this.device.title = this.$t("vlink.device.editProtocol");
      this.device.form.protocolId = row.protocolId;
      this.device.form.protocolName = row.protocolName;
      this.device.form.protocolType = row.protocolType;
      this.device.form.description = row.description;
      this.device.form.extendConfig = row.extendConfig;
      this.device.addDialogVisible = true;
    },
    getDevicePage() {
      let param = this.device.listQuery;
      // 记录设备列表查询条件
      sessionStorage.setItem("device_list_query_params", JSON.stringify(param));
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.loadingFlag = true;
      findProtocolPage(param)
          .then((response) => {
            let resultList = response.rows;
            this.dataset.tableData = resultList;
            this.dataset.pageVO.total = response.total;
            // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
            this.loadingFlag = false;
          })
          .catch((error) => {
            console.log("获取设备列表失败：" + error);
            this.loadingFlag = false;
          });
    },
  },
};
</script>

<style scoped lang="less">
.device-list-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .success-title-line {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #303133;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .el-icon-success {
      color: #67c23a;
      font-size: 24px;
      margin-right: 15px;
    }
  }
  .success-footer {
    text-align: center;
  }
}
</style>